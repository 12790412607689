<template>
  <h3 class="analysis-title flex flex-m">
    <i :class="beforeIcon"></i>
    <slot />
    <el-popover
      placement="bottom"
      trigger="hover"
      popper-class="analysis-popover"
    >
      <slot name="content" />
      <i :class="afterIcon" slot="reference"></i>
    </el-popover>
  </h3>
</template>

<script>
export default {
  name: "analysis-title",
  props: {
    /**
     * 前图标
     */
    beforeIcon: {
      type: String,
      default: "",
    },
    /**
     * 后图标
     */
    afterIcon: {
      type: String,
      default: "icon-details",
    },
  },
};
</script>

<style lang="less" scoped>
[class^="icon-"] {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: 19px;
  margin-right: 7px;
}
.icon-tit-bar {
  background-image: url("~./img/icon_tit_bar.png");
}
.icon-details {
  width: 16px;
  height: 16px;
  margin-left: 6px;
  background-image: url("~./img/details.png");
  margin-top: 3px;
}
.icon-tit-pie {
  background-image: url("~./img/icon_tit_pie.png");
}
.icon-tit-line {
  background-image: url("~./img/icon_tit_line.png");
}
.icon-tit-table {
  background-image: url("~./img/icon_tit_table.png");
}
.icon-tit-cycle {
  background-image: url("~./img/icon_tit_cycle.png");
}
.analysis-title {
  margin: 0;
  font-size: 15px;
}
</style>
<style lang="less">
.analysis-popover {
  max-width: 80%;
}
</style>
