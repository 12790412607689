<template>
  <div class="not-data">
    <!-- <img class="not-data-icon" src="./img/pic_empty_date@2x.png"/> -->
    <!-- UI更改之后去除星课堂元素 -->
    <span class="not-data-text">{{ $t("el.common.noData") }}</span>
  </div>
</template>

<script>
export default {
  name: "not-data",
};
</script>

<style lang="less" scoped>
.not-data {
  text-align: center;
  padding-top: 51px;
  padding-bottom: 47px;
}
.not-data-icon {
  width: 54px;
  height: 65px;
}
.not-data-text {
  color: #282828;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 600;
  line-height: 26px;
  vertical-align: top;
  display: inline-block;
  margin-top: 19.5px;
  margin-left: 20px;
}
</style>
