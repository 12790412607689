/**
 * 生成快捷日期范围
 * @param {number} days = 7 近期天数
 * @returns {Array}
 */
export function genQuickDateRange(days = 7) {
  let startDate = new Date();
  let endDate = new Date();
  startDate.setDate(startDate.getDate() - (days - 1));
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 0);
  return [startDate, endDate];
}

/**
 * 空函数
 */
export function noop() {}
