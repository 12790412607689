<template>
  <div
    v-sticky="{
      change: onChange,
      zIndex: 2001,
      disabled: stickyDisabled,
    }"
  >
    <div
      class="analyze-date-range"
      :class="{
        'is-sticky container': isSticky,
        EnClass: isEn,
      }"
    >
      <ul class="quick-date">
        <li
          v-for="(item, index) in quickDateList"
          :key="index"
          class="quick-date-item"
          :class="{
            active: quickDateItem === item,
          }"
          @click="handleClickQuickDate(item)"
        >
          {{ item.label }}
        </li>
      </ul>
      <el-date-picker
        v-model="dateRange"
        type="daterange"
        :class="{
          highlight: !quickDateItem,
        }"
        size="small"
        align="left"
        style="width: 280px"
        @change="handelChangeDate"
        :append-to-body="false"
      >
      </el-date-picker>
      <!-- <div
        class="select-school"
        v-if="
          $route.name == 'usage' ||
          $route.name == 'course' ||
          $route.name == 'compared'
        "
      >
        <label
          >{{ $t("el.dataAnalysis.schoolOrDepartment")
          }}{{ $t("el.symbol.colon") }}</label
        >
        <el-select
          v-model="schoolCode"
          filterable
          :placeholder="$t('el.dataAnalysis.DepartmentName')"
          style="width: 320px; margin-left: 12px"
          @change="handleChange"
          size="mini"
        >
          <el-option
            v-for="item in schoolOptions"
            :key="item.schoolCode"
            :label="item.schoolName"
            :value="item.schoolCode"
          >
          </el-option>
        </el-select>
      </div> -->
    </div>
  </div>
</template>

<script>
import { genQuickDateRange } from "../utils";
import axios from "@/plugins/axios.js";
import VueSticky from "../directive/sticky";

export default {
  name: "analyze-date-range",
  directives: {
    sticky: VueSticky,
  },
  components: {},
  props: {
    /**
     * 默认日期范围
     */
    defaultDateRange: {
      type: Array,
      default: null,
    },
    /**
     * 吸顶禁用
     */
    stickyDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateRange: [], // 日期范围
      quickDateItem: null, // 选中的快捷日期项
      isSticky: false, // 是否吸顶
      // schoolOptions: [],
      // schoolCode: "",
      isEn: false,
    };
  },
  created() {},
  watch: {
    "$i18n.locale": {
      immediate: true,
      handler(val, old) {
        console.log("$i18n.locale==========", val);
        console.log("$i18n.locale  old==========", old);
        this.isEn = val === "en_US";
        this.initDateRange();
      },
    },
  },
  computed: {
    quickDateList() {
      return [
        // 快捷日期列表
        {
          label: this.$t("el.dataAnalysis.today"),
          days: 1,
        },
        {
          label: this.$t("el.dataAnalysis.nearly7days"),
          days: 7,
        },
        {
          label: this.$t("el.dataAnalysis.nearly30"),
          days: 30,
        },
      ];
    },
  },
  methods: {
    /**
     * 初始化日期范围
     */
    async initDateRange() {
      // if (
      //   this.$route.name == "usage" ||
      //   this.$route.name == "course" ||
      //   this.$route.name == "compared"
      // ) {
      //   await this.getSchool();
      // }
      if (this.defaultDateRange && this.defaultDateRange.length >= 2) {
        console.log("0000000000000000====================");
        this.dateRange = this.defaultDateRange.slice(0);
      } else {
        if (this.defaultDateRange && this.defaultDateRange[0] == 1) {
          console.log("111111111111====================");
          this.handleClickQuickDate(this.quickDateList[0]); // 今日
        } else if (this.defaultDateRange && this.defaultDateRange[0] == 7) {
          console.log("77777777====================");
          this.handleClickQuickDate(this.quickDateList[1]); // 7日
        } else if (this.defaultDateRange && this.defaultDateRange[0] == 30) {
          console.log("30====================");
          this.handleClickQuickDate(this.quickDateList[2]); // 30日
        } else {
          console.log("44444444");
          this.handleClickQuickDate(this.quickDateList[1]); // 默认选择7日
        }
      }
    },
    /**
     * 获取学校列表数据
     */
    // getSchool() {
    //   return axios.get("/bi/detailUse/schoolList").then((res) => {
    //     this.schoolOptions = res.data;
    //     // this.$nextTick(() => {
    //     if (this.$store.state.schoolCode) {
    //       let item = this.schoolOptions.find(
    //         (item) => ~~item.schoolCode === ~~this.$store.state.schoolCode
    //       );
    //       if (item) {
    //         this.schoolCode = this.$store.state.schoolCode + "";
    //         this.$store.commit("setChangeSchool", this.schoolCode);
    //         // this.handleChange(this.schoolCode);
    //         return;
    //       }
    //     }
    //     this.schoolCode = res.data[0].schoolCode;
    //     this.$store.commit("setChangeSchool", this.schoolCode);
    //     // this.handleChange(this.schoolCode);
    //     // });
    //   });
    // },
    /**
     * 选择学校
     */
    handleChange(val) {
      this.$store.commit("setChangeSchool", val);
      this.$emit("changeSchool", val);
    },
    /**
     * 获取默认日期范围
     */
    getDefaultDateRange() {
      return genQuickDateRange(7);
    },
    /**
     * 选择日期改变
     */
    handelChangeDate(value) {
      console.log("选择日期改变=========");
      if (value && value.length >= 2) {
        let times = value[1].getTime() - value[0].getTime();
        let days = times / 1000 / 60 / 60 / 24;
        if (days > 365) {
          // 选择日期超过365天，提示框确认后默认选择到7天
          this.$alert(this.$t("el.dataAnalysis.dateRangeDesc"), "", {
            center: true,
          }).finally(() => {
            this.handleClickQuickDate(this.quickDateList[1]); // 默认选择
          });
          return;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (this.$store.state.hasOwnProperty("otherParams")) {
          this.$store.commit("setOtherParams", value);
        }
      }
      this.$emit("change", value);
      this.quickDateItem = null;
    },
    /**
     * 选择选择快捷日期
     * @param {*} item 快捷日期对象
     */
    handleClickQuickDate(item, type) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.$store.state.hasOwnProperty("otherParams")) {
        this.$store.commit("setOtherParams", [item.days]);
      }
      this.quickDateItem = item;
      this.dateRange = genQuickDateRange(item.days);
      if (type !== 1) {
        this.$emit("change", this.dateRange);
      }
    },
    /**
     * 吸顶状态改变
     * @param {*} action 是否吸顶
     */
    onChange(action) {
      this.isSticky = action;
    },
  },
};
</script>

<style lang="less" scoped>
.analyze-date-range {
  background: #fff;
  // height: 52px;
  line-height: 52px;
  &.is-sticky {
    background: #f3f4f4;
    z-index: 2001;
  }
}
.quick-date {
  margin: 0;
  padding-left: 20px;
  font-size: 0;
  display: inline-block;
  height: 100%;
  vertical-align: top;
  li {
    font-size: 14px;
    display: inline-block;
    margin-right: 46px;
    cursor: pointer;
    line-height: 19px;
    vertical-align: middle;
    &.active {
      color: #6049ff;
      font-weight: bold;
    }
  }
}
// 选中日期高亮
.el-date-editor.highlight {
  /deep/ .el-range-input {
    color: #6049ff;
  }
}
.is-sticky {
  .select-school {
    display: inline-block;
    label {
      font-size: 14px;
      line-height: 19px;
      vertical-align: middle;
      padding-left: 34px;
      margin-left: 42px;
      color: #464646;
      border-left: 1px solid #d9d9d9;
    }
  }
}
.select-school {
  display: inline-block;
  label {
    font-size: 14px;
    line-height: 19px;
    vertical-align: middle;
    padding-left: 32px;
    color: #464646;
    // border-left: 1px solid #d9d9d9;
  }
  /deep/ .el-select.el-select--mini {
    width: 300px !important;
  }
  /deep/ .el-input--mini {
    font-size: 14px;
  }
  /deep/ .el-input--mini .el-input__inner {
    height: 32px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
  }
  /deep/ .el-input--mini .el-input__icon {
    line-height: 32px;
  }
  /deep/ .el-input__suffix {
    line-height: 32px;
  }
}

.EnClass {
  .select-school /deep/ .el-select {
    width: 240px;
  }
  .quick-date {
    li {
      margin-right: 32px;
    }
  }
}
.EnClass /deep/ .el-range-editor--small.el-input__inner {
  width: 240px !important;
}
</style>
